import {createContext, useContext} from "react";
import type {FormFieldContextProps} from "~/@types";
import {createEventHandler} from "~/lib";
import {FieldContextProps, FormContextProps} from "~/@types/context/FormFieldContextProps";

export const FormContext = createContext<FormContextProps>({});

export const FieldContext = createContext<FieldContextProps>({});

export function getFormFieldContextProps({
    name,
    inheritName = false,
    onChange = undefined,
    onFocus = undefined,
    onBlur = undefined,
    onSubmit = undefined
}: FormFieldContextProps): FormFieldContextProps {
    const {
        name: formName,
        onSubmit: formOnSubmit,
        onChange: formOnChange,
        onFocus: formOnFocus,
        onBlur: formOnBlur
    } = useContext(FormContext) as FormContextProps;
    const {
        name: fieldName,
        onChange: fieldOnChange,
        onFocus: fieldOnFocus,
        onBlur: fieldOnBlur
    } = useContext(FieldContext) as FieldContextProps;

    if (inheritName) {
        name = [
            formName,
            fieldName,
            name
        ].filter(p => !!p).join(".");
    }

    const eventHandlerChains = {
        onChange: [
            formOnChange,
            fieldOnChange,
            onChange
        ],
        onFocus: [
            formOnFocus,
            fieldOnFocus,
            onFocus
        ],
        onBlur: [
            formOnBlur,
            fieldOnBlur,
            onBlur
        ],
        onSubmit: [
            formOnSubmit,
            onSubmit
        ],
    };

    const eventHandlerProps = Object.keys(eventHandlerChains).reduce(
        (handlers, type) => {
            handlers[type] = createEventHandler(eventHandlerChains[type]);
            if (!handlers[type]) {
                delete handlers[type];
            }
            return handlers;
        },
        {}
    );

    return {
        name,
        ...eventHandlerProps
    } as FormFieldContextProps;
}