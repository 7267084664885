import classNames from "classnames";

import Code from "~/components/common/Code";
import Tabs from "~/components/common/Tabs";

import CopyToClipboard from "react-copy-to-clipboard";

import classes from "./CodeTabs.module.pcss";
import {classPrefix, createStylesSelector, formatCode} from "~/lib";
import type {CodeTabsComponent, CodeTabsProps} from "~/@types/components/common/CodeTabsProps";
import type {Tab} from "rc-tabs/lib/interface";
import {TabBarExtraMap} from "rc-tabs/lib/interface";
import Button from "~/components/common/Button";
import React, {useState} from "react";
import {EditorState, EditorView} from "@uiw/react-codemirror";
import type {ViewUpdate} from "@codemirror/view";
import {syntaxTree} from "@codemirror/language";
import type {CodeParseLanguages} from "~/@types";

export default function CodeTabs<T extends CodeTabsComponent>({
    items,
    className: propsClassName,
    classes: propsClasses,
    styles: propsStyles,
    prettyPrint,
    onTabSwitch,
    onChange,
    onBlur,
    onFocus,
    extensions,
    CodeComponent,
    addCopy,
    tabBarExtraContent,
    ...props
}: CodeTabsProps<T>) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    const [sourceCodeValues, setSourceCodeValues] = useState({});
    const [activeKey, setActiveKey] = useState(items.length ? items[0].key : "");
    const [codeCopied, setCodeCopied] = useState(false);
    const setSourceCode = (name: string, state: EditorState) => {
        setSourceCodeValues(sources => {
            const source = state.doc.toString();
            return source !== sources["" + name] ? {...sources, ["" + name]: state.doc.toString()} : sources;
        });
    };
    const tabItems = items.map(({
        name,
        language,
        code,
        editable,
        disabled,
        onChange: itemOnChange,
        onBlur: itemOnBlur,
        onFocus: itemOnFocus,
        onCreateEditor: itemOnCreateEditor,
        onUpdate: itemOnUpdate,
        prettyPrint: itemPrettyPrint,
        extensions: itemExtensions,
        CodeComponent: ItemCodeComponent,
        ...item
    }): Tab => {
        const tabItem: Tab = {
            ...item,
            key: "" + item.key,
            label: item.label || name || item.key
        };
        if (typeof itemPrettyPrint === "undefined") {
            itemPrettyPrint = prettyPrint;
        }
        if (typeof itemOnChange === "undefined") {
            itemOnChange = onChange;
        }
        if (typeof itemOnFocus === "undefined") {
            itemOnFocus = onFocus;
        }
        if (typeof itemOnBlur === "undefined") {
            itemOnBlur = onBlur;
        }
        if (typeof itemExtensions === "undefined") {
            itemExtensions = extensions;
        }
        if (typeof ItemCodeComponent === "undefined") {
            ItemCodeComponent = CodeComponent;
        }
        if (typeof ItemCodeComponent === "undefined") {
            ItemCodeComponent = Code;
        }
        tabItem.children = <ItemCodeComponent
            styles={styles}
            name={"" + name}
            language={language}
            editable={editable}
            disabled={disabled}
            onChange={itemOnChange}
            onFocus={itemOnFocus}
            onBlur={itemOnBlur}
            onCreateEditor={(view: EditorView, state: EditorState) => {
                setSourceCode(tabItem.key, state);
                if (itemOnCreateEditor) {
                    itemOnCreateEditor(view, state);
                }
            }}
            onUpdate={(update: ViewUpdate) => {
                if (update.docChanged || update.viewportChanged || syntaxTree(update.startState) !== syntaxTree(update.state)) {
                    setSourceCode(tabItem.key, update.state);
                }
                if (itemOnUpdate) {
                    itemOnUpdate(update);
                }
            }}
            extensions={itemExtensions}
        >
            {formatCode(code, language !== undefined ? language as CodeParseLanguages : "json", itemPrettyPrint ? "    " : "")}
        </ItemCodeComponent>;

        return tabItem;
    });
    const tabExtra: TabBarExtraMap = {};
    if (typeof tabBarExtraContent !== "object" || React.isValidElement(tabBarExtraContent)) {
        tabExtra.right = tabBarExtraContent;
    } else if (tabBarExtraContent && typeof tabBarExtraContent === "object") {
        tabExtra.left = (tabBarExtraContent as TabBarExtraMap).left;
        tabExtra.right = (tabBarExtraContent as TabBarExtraMap).right;
    }

    if (addCopy && activeKey && typeof sourceCodeValues[activeKey] !== "undefined") {
        const copyButton = <CopyToClipboard text={sourceCodeValues[activeKey]} onCopy={(_, result) => {
            if (result) {
                setCodeCopied(true);
                setTimeout(() => setCodeCopied(false), 1000);
            }
        }}>
            <Button
                disabled={codeCopied}
                bold={false}
                ant="default"
                className={"" + styles("tab-copy-button")}
                styles={styles}
                ico={{ico: "Copy", mode: "filled"}}
                icoPosition="only"
                type="button"
            >
                {!codeCopied ? "Copy to clipboard" : "Copied!"}
            </Button>
        </CopyToClipboard>;
        if (tabExtra.right) {
            tabExtra.right = <>
                {tabExtra.right}
                {copyButton}
            </>;
        } else {
            tabExtra.right = copyButton;
        }
    }

    return <Tabs
        {...props}
        onChange={(activeKey: string) => {
            setActiveKey(activeKey);
            if (onTabSwitch) {
                onTabSwitch(activeKey);
            }
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        defaultActiveKey={undefined}
        styles={styles}
        classes={classes}
        items={tabItems}
        tabBarExtraContent={tabExtra}
        className={classNames(classPrefix("code-tabs"), propsClassName, styles("code-tabs"))}
    />;
}