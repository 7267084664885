import type {Config} from "~/@types/config";

export enum Slug{
    Login = "login",
    Logout = "logout",
    Signup = "signup",
    Restore = "restore",
    Account = "account",
    Index = "index",
    About = "about",
    Docs = "docs",
    DocsReference = Docs + "-reference",
    DocsGuides = Docs + "-guides",
    DocsChangelog = Docs + "-changelog",
    Api = "api",
    Blog = "blog",
    Invite = "invite",
    Verify = "verify",
}

function prepareSlugs(pages: Config["routes"], prefixSlug: string = ""): Config["routes"] {
    return Object.keys(pages).reduce(
        (prepPages, slug) => {
            const page = pages[slug];
            const baseSlug = slug;
            if (slug.startsWith(`${prefixSlug}-`)) {
                slug = slug.substring(prefixSlug.length + 1, slug.length);
            }

            if (page.children) {
                page.children = prepareSlugs(page.children, baseSlug);
            }

            prepPages[slug] = {
                ...page,
                slug
            };

            return prepPages;
        },
        {}
    );
}

const config: Config = {
    routes: prepareSlugs({
        [Slug.Index]: {
            path: "/",
            title: "Main page",
            editable: false
        },
        [Slug.Account]: {
            title: "Account",
            editable: false
        },
        [Slug.About]: {
            title: "About",
            editable: false
        },
        [Slug.Docs]: {
            title: "Docs",
            editable: false,
            children: {
                [Slug.DocsReference]: {"title": "API References"},
                [Slug.DocsGuides]: {
                    "title": "Guides",
                    splat: true
                },
                [Slug.DocsChangelog]: {"title": "Changelog"}
            }
        },
        [Slug.Api]: {
            title: "API",
            editable: false
        },
        [Slug.Blog]: {
            title: "Blog",
            editable: false
        },
        [Slug.Login]: {
            title: "Sign-In",
            editable: false
        },
        [Slug.Logout]: {
            title: "Log out",
            editable: false
        },
        [Slug.Signup]: {
            title: "Sign-Up",
            editable: false
        },
        [Slug.Restore]: {
            title: "Password restore",
            editable: false
        },
        [Slug.Invite]: {
            title: "Invite for:{{.email}} id:{{.invite-id}}",
            editable: false,
            params: {"invite-id":{}}
        },
        [Slug.Verify]: {
            title: "Signup verification token:{{.verify-token}}",
            editable: false,
            params: {"verify-token":{}}
        },
        "*": {
            title: "Unknown page",
            template: "404"
        }
    })
};

export default config;