import classNames from "classnames";

import {Input as AntdInput} from "antd";

import classes from "./Input.module.pcss";
import {classPrefix, createStylesSelector} from "~/lib";
import {InputProps as AntdInputProps} from "antd/es/input";
import type {InputProps} from "~/@types/components/common/InputProps";
import {PasswordProps} from "antd/es/input/Password";
import Icon from "~/components/common/Icon";

export default function Input({
    className: propsClassName,
    classes: propsClasses,
    styles: propsStyles,
    flat,
    type = "text",
    antProps = {variant: "outlined"},
    ...props
}: InputProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    const antInputProps: Omit<AntdInputProps, "styles"> = {...antProps};
    const pwProps: PasswordProps = {};

    let AntdComponent: typeof AntdInput | typeof AntdInput.Password = AntdInput;
    if (type === "password") {
        AntdComponent = AntdInput.Password;
        pwProps.iconRender = visible => <Icon loader={false} ico={visible ? "Eye" : "EyeInvisible"}/>;
    }

    return <AntdComponent
        type={type}
        {...props}
        {...antInputProps}
        {...pwProps}
        className={classNames(classPrefix("input"), propsClassName, styles("input", flat ? "flat" : false))}
    />;
}