import classNames from "classnames";

import classes from "./PageSignup.module.pcss";
import {classPrefix, createStylesSelector} from "~/lib";
import type {PageDefaultProps} from "~/@types/components/pages/PageDefaultProps";

import {useSearchParams} from "react-router";
import Link from "~/components/common/Link";
import SignupForm from "~/components/forms/SignupForm";
import type {SignupValues} from "~/@types/components/forms/SignupFormProps";
import {usePostAuthSignUpMutation} from "~/api/enhancedAuthAPI";
import {FORM_ERROR} from "final-form";
import {Slug} from "~/config";

export default function PageSignup({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
}: PageDefaultProps) {
    const [searchParams] = useSearchParams();

    const initialValues = {
        email: searchParams.get("email") || undefined,
        "invite-id": searchParams.get("invite-id") || undefined,
    };

    const [postAuthSignup, postAuthSignupProps] = usePostAuthSignUpMutation({});

    const signupHandle = async (values: SignupValues) => {
        const resp = await postAuthSignup({
            authInputSignupObjectCaptcha: {
                email: values["email"] || "",
                "invite-id": values["invite-id"],
                password: values["password"] || "",
            },
            "X-Recaptcha-Token": values["recaptcha-token"] || ""
        });
        if (resp.error) {
            return {[FORM_ERROR]: resp.error};
        }
    };

    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    return <section className={classNames(classPrefix("page-sign-up"), propsClassName, styles("page-sign-up"))}>
        <h1>Create new Account</h1>
        <SignupForm
            styles={styles}
            className={styles("sign-up-form").toString()}
            initialValues={initialValues}
            onSubmit={signupHandle}
            loading={postAuthSignupProps.isLoading}
            predefined={searchParams.has("email")}
        />
        <ul className={styles("links").toString()}>
            <li>Already has an account? <Link styles={styles} slug={Slug.Login} ico={{ico: "Login"}} icoPosition="after">Proceed to login</Link></li>
        </ul>
    </section>;
}
