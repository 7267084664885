import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {APP_ALLOWED_THEMES, APP_STATUS_INITIAL} from "~/constants/App";
import type {AppInitialState} from "~/@types/reducers/app";
import type {AllowedThemes} from "~/@types";

const initialState: AppInitialState = {
    status: null,
    location: {},
    matches: [],
    "csrf-token": null,
    "device-fingerprint": null,
    theme: "default",
};

const localStorageTheme = localStorage.getItem("app-theme") as AllowedThemes;
if (localStorageTheme && APP_ALLOWED_THEMES.includes(localStorageTheme)) {
    initialState.theme = localStorageTheme;
}

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        init: (state, action) => ({
            ...state,
            "status": APP_STATUS_INITIAL,
            "device-fingerprint": action.payload.fingerprint
        }),
        locationChange: (state, action) => {
            state.location = action.payload.location;
            state.matches = action.payload.matches;
        },
        setAppTheme: (state, {payload: theme}: PayloadAction<AllowedThemes>) => {
            if (APP_ALLOWED_THEMES.includes(theme)) {
                state = {
                    ...state,
                    theme,
                };
                localStorage.setItem("app-theme", theme);
            }

            return state;
        }
    },
    selectors: {
        getAppStatus: state => state.status,
        getLocation: state => state.location,
        getMatches: state => state.matches,
        getAppTheme: state => state.theme
    },
});

export const {init, locationChange, setAppTheme} = appSlice.actions;

export const {getAppStatus, getAppTheme} = appSlice.selectors;

export default appSlice;