import classNames from "classnames";
import Button from "~/components/common/Button";
import Field from "~/components/common/Field";
import ErrorMessage from "~/components/common/ErrorMessage";

import Form from "~/components/common/Form";

import {createStylesSelector, sanitizePath} from "~/lib";

import Loader from "~/components/common/Loader";
import Checkbox from "~/components/common/Checkbox";
import Link from "~/components/common/Link";
import {LogoutFormProps, LogoutValues} from "~/@types/components/forms/LogoutFormProps";
import {ErrorMessageProps} from "~/@types/components/common/ErrorMessageProps";
import {useId} from "react";

export default function LogoutForm({
    name,
    loading,
    classes: propsClasses,
    styles: propsStyles,
    initialValues,
    onSubmit,
    ...props
}: LogoutFormProps) {
    const styles = createStylesSelector([propsClasses, propsStyles]);

    const id = useId();

    return <Form<LogoutValues>
        {...props}
        initialValues={initialValues}
        name={name || "logout-form"}
        classes={{}}
        styles={styles}
        className={classNames(props.className, styles("logout-form"))}
        onSubmit={onSubmit}
        autoSubmitTime={5}
    >
        {({submitting, hasValidationErrors, error, submitError, touched, autoSubmitTimeLeft: propsAutoSubmitTimeLeft, cancelAutoSubmit}) => {

            const errors: ErrorMessageProps["error"][] = [];
            if (touched && error) {
                errors.push(error);
            }
            if (submitError) {
                errors.push(submitError);
            }

            const retURL = initialValues["ret-url"] ? sanitizePath(initialValues["ret-url"]) : null;

            const autoSubmitTimeLeft = propsAutoSubmitTimeLeft || -1;

            return <>
                {loading || submitting ?
                    <Loader size="large" className={styles("loader").toString()}/> : null}
                <div
                    className={classNames(styles("row", "row-logout-all"))}>
                    <Field<LogoutValues["logout-all"]> name="logout-all" type="checkbox" styles={styles}>
                        {({input, fieldName, meta: {touched, error}}) => <>
                            <Checkbox {...input} id={`${id}-logout-other`} styles={styles} name={fieldName}>Log-out all sessions</Checkbox>
                            {touched && error ? <ErrorMessage styles={styles} error={error}/> : null}
                        </>}
                    </Field>
                </div>
                <div className={classNames(styles("row", "row-error"))}>
                    {errors.map((e, i) => <ErrorMessage
                        key={i}
                        styles={styles}
                        className={"" + styles("logout-error")}
                        error={e}
                        skipErrCodes
                    />)}
                </div>
                {autoSubmitTimeLeft > 0 ? <div className={classNames(styles("row", "row-auto-log-out"))}>
                    {`(Auto log-out in ${autoSubmitTimeLeft} second${autoSubmitTimeLeft > 1 ? "s" : ""})`}
                </div> : null}
                <div className={classNames(styles("row", "row-submit"))}>
                    {retURL ? <Link
                        asButton
                        className={"" + styles("back")}
                        styles={styles}
                        to={retURL}
                    >
                        Return back
                    </Link> : null}
                    {cancelAutoSubmit && autoSubmitTimeLeft > 0 ? <Button
                        ant="default"
                        className={"" + styles("cancel")}
                        type="button"
                        styles={styles}
                        onClick={() => cancelAutoSubmit()}
                    >
                        Cancel auto log-out
                    </Button> : null}
                    <Button
                        ant="default"
                        className={"" + styles("submit")}
                        bold
                        disabled={hasValidationErrors || loading || submitting}
                        type="submit"
                        styles={styles}
                    >
                        {loading || submitting ? "loading..." : "Logout"}
                    </Button>
                </div>
            </>;
        }}
    </Form>;
}