import classNames from "classnames";
import {Fragment, lazy, ReactNode, Suspense, useMemo} from "react";
import Link from "~/components/common/Link";

import classes from "./SVG.module.pcss";
import {classPrefix, createStylesSelector, errorLog} from "~/lib";
import type {SVGProps} from "~/@types/components/common/SVGProps";
import Loader from "~/components/common/Loader";
import Span from "~/components/common/Span";

function loadSVG(path: string) {
    const [
        dir,
        ...subs
    ] = path.split("/");
    const filePath = subs.join("/").replace(/\.svg(\?.w+)?$/, "");
    switch (dir) {
        case "assets":
            return useMemo(() => lazy(() => import(`../../assets/${filePath}.svg?react`)), [path]);
        default:
            errorLog(`Assets dir "${dir}" not supported`);
            return null;
    }
}

export default function SVG({
    path,
    link = false,
    linkProps = {},
    loader = true,
    loaderProps = {},
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
}: SVGProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    const WrapComponent = link ? Link : Fragment;
    const wrapProps = link ? {...linkProps} : {};
    if (link && typeof link === "string") {
        wrapProps.to = link;
    }

    const SVGComponent = loadSVG(path);

    const loaderNode: ReactNode = loader
        ? <Loader
            styles={styles}
            className={"" + styles("loader")}
            type={typeof loader === "string" ? loader : undefined}
            {...loaderProps}
        />
        : null;

    return SVGComponent ? <WrapComponent {...wrapProps}>
        <Span className={classNames(classPrefix("svg-container"), propsClassName, styles("svg-container"))}>
            <Suspense fallback={loaderNode}>
                <SVGComponent className={"" + styles("svg")} />
            </Suspense>
        </Span>
    </WrapComponent> : null;
}