import classNames from "classnames";

import classes from "./PageVerify.module.pcss";
import {classPrefix, createStylesSelector, queryStringify} from "~/lib";
import type {PageDefaultProps} from "~/@types/components/pages/PageDefaultProps";

import {useParams} from "react-router";
import Loader from "~/components/common/Loader";
import ErrorMessage from "~/components/common/ErrorMessage";
import {useContext, useEffect, useState} from "react";
import type {ErrorMessageProps} from "~/@types/components/common/ErrorMessageProps";
import {Slug} from "~/config";
import {usePostAuthVerifyMutation} from "~/api/enhancedAuthAPI";
import Recaptcha from "~/components/features/Recaptcha";
import type {ComponentProps} from "~/@types";
import Link from "~/components/common/Link";
import recaptchaContext from "~/context/RecaptchaContext";

function VerificationHandler({styles}: ComponentProps) {
    const {
        getToken: getRecaptchaToken,
        error: recaptchaError,
        loading: recaptchaLoading,
        ready: recaptchaReady
    } = useContext(recaptchaContext);

    const [loading, setLoading] = useState(false);
    const [verified, setVerified] = useState<undefined | boolean>(undefined);
    const params = useParams();
    const verifyToken = params["verify-token"];
    const [error, setVerifyError] = useState<ErrorMessageProps["error"]>("");

    const [postAuthVerify, {data: verifyResult, isLoading: verifyLoading}] = usePostAuthVerifyMutation();

    useEffect(() => {
        if (verifyToken) {
            if (recaptchaReady && getRecaptchaToken) {
                (async () => new Promise((resolve) => {
                    setLoading(true);
                    setTimeout(async () => {
                        try {
                            const recaptchaToken = await getRecaptchaToken();
                            const {data: verifyResult, error: verifyError} = await postAuthVerify({
                                authInputVerifyObjectCaptcha: {"verify-token": verifyToken},
                                "X-Recaptcha-Token": recaptchaToken
                            });
                            setLoading(false);
                            if (verifyError) {
                                setVerifyError(verifyError as Error);
                            } else {
                                setVerified(verifyResult?.success || false);
                                resolve(verifyResult);
                            }
                        } catch (e) {
                            setLoading(false);
                            setVerifyError(e as Error);
                        }
                    }, 100);
                }))();
            }
        } else {
            setVerifyError("invalid verification data");
        }
    }, [verifyToken, recaptchaReady, getRecaptchaToken]);

    const isLoading = loading || recaptchaLoading || verifyLoading;

    const verifyError = recaptchaError || error || !isLoading && verified === false && "not verified";

    return verifyError
        ? <ErrorMessage styles={styles} prefix="Email verification error: " error={verifyError} />
        : (isLoading
            ? <Loader styles={styles} size="large"/>
            : <div className={styles && styles("verify-result").toString()}>
                <p>Hello! Your email is successfully verified!</p>
                <p>Now you can proceed to the <Link slug={Slug.Login} to={verifyResult ? "?" + queryStringify({email: verifyResult.email}): ""}>login page</Link></p>
            </div>
        );
}

export default function PageVerify({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
}: PageDefaultProps) {

    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    return <section className={classNames(classPrefix("page-verify"), propsClassName, styles("page-verify"))}>
        <h1>Email Verification</h1>
        <Recaptcha><VerificationHandler styles={styles}/></Recaptcha>
    </section>;
}
