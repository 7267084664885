import classNames from "classnames";

import classes from "./Icon.module.pcss";
import {capitalize, classPrefix, createStylesSelector} from "~/lib";
import type {IconProps} from "~/@types/components/common/IconProps";

import {lazy, ReactNode, Suspense, useMemo} from "react";

import {IconSet} from "~/constants/Icons";
import Loader from "~/components/common/Loader";

function loadIcon(mode: string, ico: string) {
    if (mode === "auto") {
        mode = Object.keys(IconSet).reduce(
            (mode, set) => {
                if (!mode && IconSet[set].includes(ico)) {
                    mode = set;
                }
                return mode;
            },
            ""
        );
        if (!mode) {
            throw new Error(`Can't find mode for icon ${ico}`);
        }
    }
    const icoFile = ico + capitalize(mode);

    return {
        IconComponent: useMemo(() => lazy(() => import(`../../../node_modules/@ant-design/icons/es/icons/${icoFile}.js`)), [icoFile]),
        ico,
        mode
    };
}

export default function Icon({
    title: propsTitle,
    ico: propsIco,
    mode: propsMode = "auto",
    rotate = 0,
    spin = false,
    twoToneColor,
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    loader = true,
    loaderProps = {},
    onClick,
    onFocus,
    onBlur
}: IconProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    const {IconComponent, ico, mode} = loadIcon(propsMode, propsIco);

    const loaderNode: ReactNode = loader
        ? <Loader
            styles={styles}
            className={"" + styles("loader")}
            type={typeof loader === "string" ? loader : undefined}
            {...loaderProps}
        />
        : null;

    return <span
        title={propsTitle !== undefined ? (propsTitle === "" ? undefined : propsTitle) : `${ico} ${mode}`}
        className={classNames(
            classPrefix("icon"),
            propsClassName,
            styles("icon", `icon-${ico}`)
        )}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
    >
        <Suspense fallback={loaderNode}>
            <IconComponent
                rotate={rotate}
                spin={spin}
                twoToneColor={twoToneColor}
            />
        </Suspense>
    </span>;
}
