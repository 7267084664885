import classNames from "classnames";

import classes from "./PageLogout.module.pcss";
import {classPrefix, createStylesSelector, sanitizePath} from "~/lib";
import type {PageDefaultProps} from "~/@types/components/pages/PageDefaultProps";
import {useNavigate, useSearchParams} from "react-router";
import LogoutForm from "~/components/forms/LogoutForm";
import type {LogoutValues} from "~/@types/components/forms/LogoutFormProps";
import {Slug} from "~/config";
import {useSelector} from "react-redux";
import {getSlugInfo} from "~/reducers/navigation";
import {FORM_ERROR} from "final-form";
import {useDeleteAuthSignOutMutation} from "~/api/enhancedAuthAPI";

export default function PageLogout({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
}: PageDefaultProps) {
    const [searchParams] = useSearchParams();

    const {path: loginPath} = useSelector(getSlugInfo)(Slug.Login);

    let retURL = searchParams.get("_ret") || loginPath || "";

    const initialValues: LogoutValues = {
        "logout-all": false,
        "ret-url": retURL,
    };

    const navigate = useNavigate();

    const [deleteAuthSignOut, deleteAuthSignOutProps] = useDeleteAuthSignOutMutation({});

    const loginHandle = async (values: LogoutValues) => {
        const resp = await deleteAuthSignOut({
            "logout-all": values["logout-all"],
            "ret-url": retURL,
        });
        if (resp.error) {
            return {[FORM_ERROR]: resp.error};
        } else if (resp.data) {
            if (!retURL && resp.data["ret-url"]) {
                retURL = resp.data["ret-url"];
            }
            navigate(sanitizePath(retURL));
        }
    };

    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    return <section className={classNames(classPrefix("page-logout"), propsClassName, styles("page-logout"))}>
        <h1>Logout</h1>
        <LogoutForm
            styles={styles}
            className={styles("login-form").toString()}
            initialValues={initialValues}
            loading={deleteAuthSignOutProps.isLoading}
            onSubmit={loginHandle}
        />
    </section>;
}
