import {createSelector, createSlice, isAnyOf} from "@reduxjs/toolkit";
import {enhancedNavigationAPI} from "~/api/enhancedNavigationAPI";
import {NAVIGATION_STATUS_INITIAL, NAVIGATION_STATUS_LOADED, NAVIGATION_STATUS_LOADING} from "~/constants/Navigation";
import {prepareGuidesRoutes, sanitizePath} from "~/lib";
import {locationChange} from "~/reducers/app";

import config from "~/config";

import type {NavigationInitialState, NavigationMenu, NavigationSlugInfo} from "~/@types/reducers/navigation";
import type {ConfigRoutes} from "~/@types/config";
import type {DocsOutputMenusListType2} from "~/@types/api/docsAPI";

const prepareSlugInfo = (pages: ConfigRoutes, prefixSlug: string = "", prefixPath: string = ""): NavigationSlugInfo => {
    return Object.keys(pages).reduce(
        (slugs, slug) => {
            const page = pages[slug];
            const path = sanitizePath([
                prefixPath,
                page.path || slug
            ].join("/"));

            const prefixedSlug = prefixSlug ? `${prefixSlug}-${slug}` : slug;
            slugs[prefixedSlug] = {
                path,
                title: page.title
            };
            if (page.children) {
                slugs = {
                    ...slugs,
                    ...(prepareSlugInfo(page.children, prefixedSlug, path))
                };
            }

            return slugs;
        },
        {}
    );
};

const initialState: NavigationInitialState = {
    status: null,
    slugInfo: prepareSlugInfo(config.routes),
    menus: {},
    current: null
};

export const navigationSlice = createSlice({
    name: "navigation",
    initialState,
    reducers: {
        init: (state) => ({
            ...state,
            status: NAVIGATION_STATUS_INITIAL,
        }),
        injectDocsGuides: (state, {payload: {slugPrefix, menu, guides}}: {payload: DocsOutputMenusListType2 & {slugPrefix: string}}) => {
            if (!slugPrefix) {
                slugPrefix = "docs-guides";
            }

            const guidesSlugInfo = state.slugInfo[slugPrefix];
            return {
                ...state,
                menus: {
                    ...state.menus,
                    "guides": {
                        ...menu,
                        slugPrefix
                    }
                },
                slugInfo: {
                    ...state.slugInfo,
                    ...prepareSlugInfo(prepareGuidesRoutes(guides), slugPrefix, guidesSlugInfo.path)
                }
            };
        }
    },
    extraReducers: builder => {
        builder.addCase(
            locationChange,
            (state, action) => ({
                ...state,
                current: action.payload
            })
        );
        builder.addMatcher(
            isAnyOf(enhancedNavigationAPI.endpoints.getNavigationMenus.matchPending),
            (state) => ({
                ...state,
                status: NAVIGATION_STATUS_LOADING
            })
        );
        builder.addMatcher(
            enhancedNavigationAPI.endpoints.getNavigationMenus.matchRejected,
            (state) => {
                return {
                    ...state,
                    status: NAVIGATION_STATUS_INITIAL,
                };
            }
        );
        builder.addMatcher(
            enhancedNavigationAPI.endpoints.getNavigationMenus.matchFulfilled,
            (state, {payload}) => ({
                ...state,
                status: NAVIGATION_STATUS_LOADED,
                menus: {
                    ...state.menus,
                    ...(payload.menu || {})
                }
            })
        );
    },
    selectors: {
        getNavigationStatus: state => state.status,
        getMenu: createSelector(
            state => state.menus,
            menus => (name: string): NavigationMenu => menus[name] || {}
        ),
        getSlugInfo: createSelector(
            state => state.slugInfo,
            slugInfo => (slug: string): NavigationSlugInfo[string] => slugInfo[slug] || {path: null}
        ),
    }
});

export const {init, injectDocsGuides} = navigationSlice.actions;

export const {getNavigationStatus, getSlugInfo, getMenu} = navigationSlice.selectors;

export default navigationSlice;