import {
    createBrowserRouter,
    type RouteObject,
    RouterProvider as ReactRouterProvider,
    useInRouterContext,
    useRoutes
} from "react-router";
import {sanitizePath} from "~/lib";
import type {RouterProps} from "~/@types/components/features/RouterProps";
import ErrorBoundary from "~/components/common/ErrorBoundary";
import Route from "~/components/features/Route";
import {ReactElement} from "react";
import type {NonUndefined} from "~/@types";

export function prepareRoutes(
    pages: RouterProps["pages"],
    renderFunction: NonUndefined<RouterProps["renderFunction"]>,
    prefixSlug: RouterProps["prefixSlug"] = "",
    prefixPath: RouterProps["prefixPath"] = ""
): RouteObject[] {
    return Object.keys(pages).map(slug => {
        const page = pages[slug];
        let {element} = page;
        let path: string = "";

        const resolvedSlug = prefixSlug ? `${prefixSlug}-${slug}` : slug;

        if (!element) {
            const pathParts: string[] = [
                prefixPath,
                page.path || slug,
            ];
            if (page.params) {
                const pageParams = page.params;
                const paramsParts: string[] = Object.keys(pageParams).map(
                    (param: string) => `:${param}${pageParams[param].optional ? "?" : ""}`
                );
                pathParts.push(...paramsParts);
            }
            if (page.splat) {
                pathParts.push("*");
            }

            path = sanitizePath(pathParts.join("/"));

            element = renderFunction(resolvedSlug, page);
        }

        const route: RouteObject = {
            id: slug,
            path,
            caseSensitive: false,
        };
        if (page.children) {
            route.children = prepareRoutes(
                {
                    "/": {index: true, element},
                    ...page.children
                },
                renderFunction,
                resolvedSlug,
                path
            );
        } else {
            route.element = element;
        }

        const isIndex = typeof page.index !== "undefined" ? page.index : path === "/";
        if (!isIndex) {
            route.index = false;
        }

        return route;
    });
}

export default function Router({
    pages,
    basename = "/", //APP_BASE_PATH ? APP_BASE_PATH : "/",
    RouteComponent,
    renderFunction = (slug, page) => <ErrorBoundary>
        <Route Component={RouteComponent} slug={slug} page={page}/>
    </ErrorBoundary>,
    prefixSlug = "",
    prefixPath = ""
}: RouterProps){
    let ret: ReactElement | null;
    const routes = prepareRoutes(
        pages,
        renderFunction,
        prefixSlug,
        prefixPath
    );
    if (useInRouterContext()) {
        ret = useRoutes(routes);
    } else {
        const router = createBrowserRouter(
            routes,
            {
                basename,
                future: {
                    v7_relativeSplatPath: true,
                    v7_fetcherPersist: true,
                    v7_normalizeFormMethod: true,
                    v7_partialHydration: true,
                    v7_skipActionErrorRevalidation: true,
                }
            }
        );
        ret = <ReactRouterProvider router={router}/>;
    }

    return ret;
}