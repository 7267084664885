import classNames from "classnames";
import Page from "~/components/templates/Page";

import classes from "./Main.module.pcss";
import {classPrefix, createStylesSelector} from "~/lib";
import type {MainProps} from "~/@types/components/templates/MainProps";

export default function Main({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    ...props
}: MainProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    return <main className={classNames(classPrefix("main"), propsClassName, styles("main"))}>
        <Page {...props} className={"" + styles("page")} styles={styles}/>
    </main>;
}