import {userAPI} from "~/api/userAPI";
import {TagAuth} from "~/constants/Tags";

export const enhancedUserAPI = userAPI.enhanceEndpoints({
    addTagTypes: [TagAuth],
    endpoints: {getUserByUserId: {providesTags: (_r, _e, arg) => [{type: TagAuth, id: arg}]}}
});

export const {
    useGetUserQuery,
    useLazyGetUserQuery,
    useGetUserByUserIdQuery,
    useLazyGetUserByUserIdQuery,
} = enhancedUserAPI;