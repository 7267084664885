import ReactDOM from "react-dom/client";

import App from "~/components/features/App";
import {classPrefix, errorLog} from "~/lib";
import Wrapper from "~/components/templates/Wrapper";
import Loader from "~/components/common/Loader";
import {createTransform} from "redux-persist";
import {AccountInitialState} from "~/@types/reducers/account";

const rootID = classPrefix("root");
const rootElement = document.getElementById(rootID);
if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);

    root.render(<App
        RouteComponent={Wrapper}
        persist={true}
        persistConfig={{
            whitelist: ["account"],
            transforms: [createTransform<AccountInitialState, any>(
                // transform state on its way to being serialized and persisted.
                (inboundState, key) => {
                    return key === "account" ? {
                        ...inboundState,
                        state: undefined
                    } : inboundState;
                },
                // transform state being rehydrated
                (outboundState, key) => {
                    return key === "account" ? {
                        ...outboundState,
                        state: undefined
                    } : outboundState;
                },
                // define which reducers this transform gets called for.
                { whitelist: ["account"] }
            )],
        }}
        persistLoading={<Loader size="large" position="fixed"/>}
    />);
} else {
    errorLog(`Root element with id #${rootID} is missing`);
}
