import classNames from "classnames";

import {Checkbox as AntdCheckbox} from "antd";

import classes from "./Checkbox.module.pcss";
import {classPrefix, createStylesSelector} from "~/lib";
import type {CheckboxProps as AntdCheckboxProp} from "antd/es/checkbox";
import type {CheckboxProps} from "~/@types/components/common/CheckboxProps";

export default function Checkbox({
    className: propsClassName,
    classes: propsClasses,
    styles: propsStyles,
    antProps,
    ...props
}: CheckboxProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    const antCheckboxProps: Omit<AntdCheckboxProp, "styles"> = {...antProps};

    return <AntdCheckbox
        {...props}
        {...antCheckboxProps}
        className={classNames(classPrefix("checkbox"), propsClassName, styles("checkbox"))}
    />;
}