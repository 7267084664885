import {DependencyList, EffectCallback, useEffect, useMemo, useRef} from "react";
import url from "url";
import qs from "qs";
import {default as lodashToPath} from "lodash/toPath";
import {default as lodashGet} from "lodash/get";
import {default as lodashSet} from "lodash/set";
import {default as lodashHas} from "lodash/has";
import {default as lodashIsEqual} from "lodash/isEqual";
import bytes, {Unit as BytesUnit} from "bytes";
import xml2js from "xml2js";
import {
    abbrStr as libAbbrStr,
    capitalize as libCapitalize,
    randRange as libRandRange,
    sanitizePath as libSanitizePath,
    uniqueToken as libUniqueToken
} from "../lib";
import type {
    CodeParsedReturn,
    CodeParseLanguages,
    EventHandler,
    EventObject,
    FormatCodeCode,
    FormatNumberOptions,
    FormFieldParams,
    HMSParts,
    ProbeReportDiff,
    ProbeReportDiffPath,
    PropertyPath,
    StyleSelector,
    StyleSelectorCreator,
    StyleSelectorStyles,
    URLScheme
} from "~/@types";
import type {SchemasGuideStructure} from "~/@types/api/docsAPI";
import type {ConfigRoutes} from "~/@types/config";
import type {PageTplNameTypeFixed} from "~/@types/components/templates/PageProps";
import type {OpenAPI3} from "openapi-typescript";
import type {JSONSchema7} from "json-schema";
import {ClassNameArray} from "~/classes";
import {AccountInitialState} from "~/@types/reducers/account";

export function isDev(): boolean {
    return VITE__IS_DEV;
}

// noinspection JSUnusedGlobalSymbols
export function consoleFunction(devOnly: boolean = false, fnc: "log" | "warn" | "error" = "log", ...args: any[]) {
    return !devOnly || isDev() ? console[fnc](...args) : undefined; //eslint-disable-line no-console
}

// noinspection JSUnusedGlobalSymbols
export const log = (...args: unknown[]) => {
    return consoleFunction(false, "log", ...args);
};

// noinspection JSUnusedGlobalSymbols
export const warn = (...args: unknown[]) => {
    return consoleFunction(false, "warn", ...args);
};

// noinspection JSUnusedGlobalSymbols
export const errorLog = (...args: unknown[]) => {
    return consoleFunction(false, "error", ...args);
};

// noinspection JSUnusedGlobalSymbols
export const devLog = (...args: unknown[]) => {
    return consoleFunction(true, "log", ...args);
};

// noinspection JSUnusedGlobalSymbols
export const devWarn = (...args: unknown[]) => {
    return consoleFunction(true, "warn", ...args);
};

// noinspection JSUnusedGlobalSymbols
export const devErrorLog = (...args: unknown[]) => {
    return consoleFunction(true, "error", ...args);
};

export const classPrefix = (className: string) => `${VITE__PREFIX__}${className}`;


export const stylesSelector: StyleSelectorCreator = (name: (string|boolean)|(string|boolean)[], styles: StyleSelectorStyles, warnMissing: boolean): ClassNameArray => {
    let ret: ClassNameArray = [];
    if (name instanceof Array && name.length === 1) {
        name = name[0];
    }
    if (name instanceof Array && name.length > 1) {
        ret = (new ClassNameArray()).concat(...(name.map(n => stylesSelector(n, styles, warnMissing)).filter(c => !!c)));
    } else if (typeof name === "string") {
        const stylesArray = styles instanceof Array ? styles : [styles];
        ret = stylesArray.reduce(
            (ret, styles) => {
                if (styles) {
                    let foundStyle: string | string[] | undefined = undefined;
                    if (typeof styles === "object") {
                        foundStyle = styles[classPrefix(<string>name)] || undefined;
                    } else if (typeof styles === "function") {
                        foundStyle = styles(<string>name) || undefined;
                    }
                    if (foundStyle && foundStyle.length) {
                        ret = ret.concat(foundStyle);
                    }
                }

                return ret;
            },
            (new ClassNameArray())
        );
        if (warnMissing && !ret.length) {
            log(`Missing class name '${name}'`);
        }
    }

    return ret;
};

export const createStylesSelector = (styles: StyleSelectorStyles, warnMissing: boolean = false): StyleSelector =>
    (...name: (string|boolean)[]) =>
        stylesSelector(name, styles, warnMissing);

// noinspection JSUnusedGlobalSymbols
export const abbrStr = libAbbrStr;

// noinspection JSUnusedGlobalSymbols
export const randRange = libRandRange;

// noinspection JSUnusedGlobalSymbols
export const uniqueToken = libUniqueToken;

// noinspection JSUnusedGlobalSymbols
export const stringToPath = (path: string): PropertyPath => lodashToPath(path);

// noinspection JSUnusedGlobalSymbols
export const hasIn = (object: object, path: PropertyPath) => lodashHas(object, path);

// noinspection JSUnusedGlobalSymbols
export const getIn = (object: object, path: PropertyPath, defaultValue: unknown) => lodashGet(object, path, defaultValue);

// noinspection JSUnusedGlobalSymbols
export const setIn = (object: object, path: PropertyPath, value: unknown) => lodashSet(object, path, value);

// noinspection JSUnusedGlobalSymbols
export const deepEqual = (a: any, b: any) => lodashIsEqual(a, b);

export const capitalize = libCapitalize;

export const hmsPartsNumbers = (seconds: number, round: boolean = false): HMSParts => {
    if (isNaN(seconds)) {
        seconds = 0;
    }
    const roundSeconds = Math.round(seconds);
    const ms = round ? 0 : Math.round((seconds - roundSeconds) * 1000);
    seconds = roundSeconds;
    const hour= Math.floor(seconds / 3600);
    const min = Math.floor((seconds - (hour * 3600)) / 60);
    const sec = seconds - ((60 * hour) + min) * 60;

    return [
        hour,
        min,
        sec,
        ms
    ];
};

export const hmsParts = (seconds: number, round: boolean = false, unitSeparator: string = ""): string[] => {
    const parts: string[] = [];

    const [hour, min, sec, ms] = hmsPartsNumbers(seconds, round);

    if (hour > 0) {
        parts.push(`${hour}${unitSeparator}h`);
    }
    if (min > 0) {
        parts.push(`${min}${unitSeparator}m`);
    }
    parts.push(`${sec > 0 ? sec : 0}${unitSeparator}s`);
    if (ms > 0) {
        parts.push(`${ms}${unitSeparator}ms`);
    }

    return parts;
};

// noinspection JSUnusedGlobalSymbols
export const hms = (seconds: number, round: boolean = false, unitSeparator: string = ""): string => hmsParts(seconds, round, unitSeparator).join(" ");

// noinspection JSUnusedGlobalSymbols
export const queryStringify = (params: unknown, opts: object = {}) => qs.stringify(params, {encodeValuesOnly: true, ...opts}).replace(/=($|&)/gi, "$1");

// noinspection JSUnusedGlobalSymbols
export const parseURLString = (urlToParse: string): URLScheme | false => {
    let urlScheme: URLScheme | false;
    const urlAuthRegExp = /\/\/([^:/]*)(:[^@/]*)?@/;
    if (urlToParse) {
        if (urlToParse.indexOf("://") < 0) {
            urlToParse = "//" + urlToParse;
        } else {
            urlToParse = urlToParse.replace(/(\w+:\/\/)*(\w+:\/\/)/ig, "$2");
        }
        if (urlAuthRegExp.test(urlToParse)) {
            urlToParse = urlToParse.replace(
                urlAuthRegExp,
                (_match: string, login: string, pass: string) => {
                    let parsedLogin = login || "";
                    let parsedPass = pass ? pass.substring(1) : "";
                    try {
                        parsedLogin = decodeURIComponent(parsedLogin);
                    } catch (_e) {
                        //do nothing
                    }
                    try {
                        parsedPass = decodeURIComponent(parsedPass);
                    } catch (_e) {
                        //do nothing
                    }
                    parsedLogin = encodeURIComponent(parsedLogin);
                    parsedPass = encodeURIComponent(parsedPass);

                    return  `//${parsedLogin}${pass ? ":" + parsedPass : ""}@`;
                }
            );
        }
    }
    try {
        urlScheme = url.parse(
            urlToParse,
            false,
            true
        );
    } catch (_e) {
        urlScheme = false;
    }
    if (urlScheme) {
        urlScheme.query = urlScheme.search ? qs.parse(urlScheme.search.replace(/^\?/, "")) : {};

        urlScheme.hostname = urlScheme.hostname || "";
        urlScheme.pathname = urlScheme.pathname || "";
        try {
            urlScheme.pathname = decodeURIComponent(urlScheme.pathname);
        } catch (_e) {
        }
    }

    return urlScheme;
};

// noinspection JSUnusedGlobalSymbols
export const composeURLString = (urlScheme: URLScheme): string => {
    const urlParts: string[] = [];

    if (urlScheme.protocol) {
        urlParts.push(urlScheme.protocol + "//");
    }
    if (urlScheme.host) {
        urlParts.push(urlScheme.host);
    }
    if (urlScheme.pathname) {
        urlParts.push(sanitizePath(urlScheme.pathname));
    }
    if (urlScheme.query || urlScheme.search) {
        const qString = typeof urlScheme.query === "object"
            ? queryStringify(urlScheme.query)
            : (urlScheme.query || urlScheme.search || "");
        if (qString.length > 0) {
            urlParts.push("?" + qString);
        }
    }

    return urlParts.join("");
};

// noinspection JSUnusedGlobalSymbols
export const formatNumber = (number: (number | string), options: FormatNumberOptions = {}): string => {
    const {digits, digitSeparator, thousandSeparator} = {
        digits: 2,
        digitSeparator: ".",
        thousandSeparator: ",",
        ...options
    };

    if (typeof number === "string") {
        number = parseFloat(number);
    }
    if (isNaN(number)) {
        number = 0;
    }

    const optionalDigits = digits < 0;
    const digitsNum = Math.abs(digits);
    const digitsNumber = Math.pow(10, digitsNum);

    const fixed = (Math.round(number * digitsNumber) / digitsNumber).toFixed(digitsNum);
    const parts = fixed.split(".");

    if (optionalDigits && parts[1] && parseInt(parts[1]) === 0) {
        parts.pop();
    }
    return parts.map((part, k) => thousandSeparator && k === 0
        ? part.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)
        : part)
        .join(digitSeparator);
};

// noinspection JSUnusedGlobalSymbols
export const formatBytes = (bytesVal: number | string, unit: string | null = null, unitSeparator: string = ""): string => {
    if (typeof bytesVal === "string") {
        bytesVal = parseFloat(bytesVal);
    }
    if (isNaN(bytesVal)) {
        bytesVal = 0;
    }

    const minUnitsMap = {
        KB: 1 << 10,
        MB: 1 << 20,
        GB: 1 << 30,
        TB: Math.pow(2, 40),
        PB: Math.pow(2, 50),
    };
    let minUnit: string = "GB";
    let minUnitUpper: number = -1;
    if (unit) {
        minUnit = unit.substring(1);
        if (unit.length > 2) {
            if (unit[0] === ">") {
                minUnitUpper = 0;
            }
            unit = null;
        }
    }
    if (!unit) {
        const minVal = minUnitsMap[minUnit];
        if (minUnitUpper < 0) {
            minUnitUpper = minVal / 100;
        }
        unit = bytesVal < minVal && bytesVal >= minUnitUpper ? minUnit : "";
    }

    const retVal = bytes(
        bytesVal,
        {
            unit: unit as BytesUnit,
            unitSeparator,
        }
    );

    return retVal ? retVal : `0${unitSeparator}${minUnit}`;
};

export function isNumber(string: unknown): boolean {
    let ret: boolean = typeof string === "number";
    if (!ret && typeof string === "string") {
        ret = !isNaN(parseFloat(string));
    }
    return ret;
}

export function toNumber(string: unknown): number {
    return isNumber(string) ? parseFloat("" + string) : 0;
}

export function useOnMountUnsafe(effect: EffectCallback, deps: DependencyList = [], refVal:boolean | string | number = true) {
    const initialized = useRef<boolean | string | number>(false);

    useEffect(() => {
        if (!initialized.current || initialized.current !== refVal) {
            initialized.current = refVal;
            effect();
        }
    }, deps);
}

export const sanitizePath = libSanitizePath;

export function formatCode(
    code: FormatCodeCode,
    language: CodeParseLanguages,
    indent: string = "    ",
    raw: boolean = false
): string {
    let ret: string = "";
    if (typeof code === "object") {
        switch (language) {
            case "xml": {
                const xmlBuilder = new xml2js.Builder({
                    renderOpts: {
                        pretty: !raw && !!indent,
                        indent
                    },
                    xmldec: {version: "1.0"},
                    headless: false
                });
                ret = xmlBuilder.buildObject(code);
                break;
            }
            case "www-form": {
                const data = queryStringify(code);
                ret = raw ? data : `Content-Type: application/x-www-form-urlencoded
Content-Length: ${data.length}

${data}`;
            }
                break;
            default:
            case "json":
            case "javascript":
                ret = JSON.stringify(code, null, raw ? "" : indent);
                break;
        }
    } else {
        ret = "" + code;
    }

    return ret;
}

export function parseCode(codeString: string, language: CodeParseLanguages | "auto" = "auto"): {code: CodeParsedReturn, language: CodeParseLanguages, error: unknown} {
    codeString = "" + codeString;
    let code: CodeParsedReturn = null, error: unknown = null;
    try {
        switch (language) {
            case "xml": {
                xml2js.parseString(
                    codeString,
                    {
                        trim: true,
                        normalizeTags: true,
                        explicitArray: false,
                        ignoreAttrs: true
                    },
                    (err, result) => {
                        if (!err) {
                            code = result;
                        }
                    }
                );
                break;
            }
            default:
            case "json":
            case "javascript":
                code = JSON.parse(("" + codeString).replace(/,(\s*[\]}])/gi, "$1"));
                break;
            case "www-form": {
                const [, body] = codeString.split(/[\n\r]{2,}/); /* @todo: parse headers as well */
                if (body) {
                    code = qs.parse(body);
                }
                break;
            }
            case "auto":
                ["json", "xml", "www-form"].some(mode => {
                    const parsed = parseCode(codeString, mode as CodeParseLanguages);
                    if (parsed.code) {
                        code = parsed.code;
                        language = parsed.language;
                        error = null;
                        return true;
                    } else {
                        error = parsed.error;
                    }
                });
                break;
        }
    } catch (e) {
        error = e;
    }

    return {
        code,
        language: language as CodeParseLanguages,
        error
    };
}

export function reformatCode(
    codeString: string,
    language: CodeParseLanguages | "auto" = "auto",
    indent: string = "    ",
    raw: boolean = false
): string {
    const parsed = parseCode(codeString, language);
    if (parsed.error) {
        devWarn("Code re-format error:", parsed.error);
    }
    return !parsed.error && parsed.code ? formatCode(parsed.code, parsed.language, indent, raw) : codeString;
}

export function createEventHandler(chain: EventHandler[], wrapEvent: ((e: EventObject) => EventObject) | null = null): EventHandler {
    let handler: EventHandler;
    const handlersChain = chain.filter(h => typeof h === "function");
    if (handlersChain.length > 0) {
        handler = !wrapEvent && handlersChain.length === 1
            ? handlersChain[0]
            : (e: EventObject | undefined, ...args: unknown[]) => {
                if (e && wrapEvent) {
                    e = wrapEvent(e);
                }
                return handlersChain.reduce(
                    (result: unknown, eventHandler: EventHandler): unknown => typeof eventHandler === "function" ? eventHandler(e, ...args, result) : undefined,
                    undefined
                );
            };
    }

    return handler;
}

export function wrapTargetEvent(e: EventObject, type: string | null = null, target: object = {}): EventObject {
    if (typeof e !== "object" || !e.target) {
        target = {
            ...target,
            value: e
        };
        e = new Event(e.type || type || "custom") as EventObject;
        Object.defineProperty(e, "target", {writable: false, value: target});
    }

    return e;
}

export function isPromise(obj: {then?: () => unknown}) {
    return !!obj && (typeof obj === "object" || typeof obj === "function") && typeof obj.then === "function";
}

export function parseProbeReportDiff(diff: object, path: ProbeReportDiffPath = []): ProbeReportDiff[] {
    let parsed: ProbeReportDiff[] = [];
    Object.keys(diff).forEach(k => {
        const diffPart = diff[k];
        if (diffPart && typeof diffPart === "object") {
            const pathKey = (diff instanceof Array) ? parseInt(k) : k;
            const subPath: ProbeReportDiffPath = path.length > 0 && path[path.length - 1] === pathKey
                ? [...path]
                : [...path, pathKey];
            if (typeof diffPart.chosen !== "undefined") {
                const diffRow: ProbeReportDiff = {
                    path: subPath,
                    diff: diffPart
                };
                parsed.push(diffRow);
            } else {
                parsed = parsed.concat(parseProbeReportDiff(
                    diffPart,
                    subPath
                ));
            }
        }
    });

    return parsed;
}

export function parseProbeReportCode(code: object | [], path: ProbeReportDiffPath = []): ProbeReportDiff[] {
    let diff: ProbeReportDiff[] = [];
    Object.keys(code).forEach(k => {
        if (k === "diff") {
            diff = diff.concat(parseProbeReportDiff(code[k], path));
        } else if (code[k] && typeof code[k] === "object") {
            const pathKey = (code instanceof Array) ? parseInt(k) : k;
            const parsed = parseProbeReportCode(code[k], [...path, pathKey]);
            diff = diff.concat(parsed);
        }
    });

    return diff;
}

export function parseDateString(dateString: string | number | undefined): Date | undefined {
    let dateObject: Date | undefined = undefined;
    try {
        dateObject = typeof dateString === "undefined"
            ? new Date()
            : new Date(dateString);

        if (!isDateValid(dateObject)) {
            dateObject = undefined;
        }
    } catch (e) {
        warn(e);
    }

    return dateObject;
}

export function isDateValid(date: Date) {
    return !isNaN(date.valueOf());
}

export function prepareGuidesRoutes(guides: SchemasGuideStructure | undefined): ConfigRoutes {
    return Object.entries(guides || {}).reduce<ConfigRoutes>(
        (routes, [slug, guideData]) => {
            routes[slug] = {
                path: guideData.path,
                template: guideData.template as PageTplNameTypeFixed,
                title: guideData.title,
            };
            if (guideData.children) {
                routes[slug].children = prepareGuidesRoutes(guideData.children);
            }

            return routes;
        },
        {}
    );
}

export function applyTpl(tpl: string, params: {[key: string]: string}, noValue: string = "<no value>"): string {
    return tpl.includes("{{.")
        ? tpl.replace(/\{\{\.([\w-_]+)}}/gi, (_, nm) => typeof params[nm] !== "undefined" ? params[nm] : noValue)
        : tpl;
}

export function resolveSchemaRef(rootSchema: OpenAPI3, schema: object): object {
    let mergedSchema: object = {...schema};
    if (schema["$ref"]) {
        const componentPath = schema["$ref"].split("/");
        componentPath.shift();
        const resolved = getIn(rootSchema as object, componentPath, null);
        if (resolved !== null) {
            mergedSchema = {...schema, ...resolved};
            delete mergedSchema["$ref"];
        }
    }

    Object.keys(mergedSchema).forEach(key => {
        if (mergedSchema[key] && typeof mergedSchema[key] === "object") {
            mergedSchema[key] = resolveSchemaRef(rootSchema, mergedSchema[key]);
        }
    });

    return mergedSchema;
}

export function prepareFormFieldParamsFromSchema<FormValues extends Record<string, any>>(openapiSchema: OpenAPI3 | undefined, schemaName: string | string[]): FormFieldParams<FormValues> {
    return useMemo<FormFieldParams<FormValues>>(
        () => {
            const props: FormFieldParams<FormValues> = {};
            if (openapiSchema) {
                let oaSchema: object | undefined = undefined;
                if (Array.isArray(schemaName)) {
                    schemaName.some(sn => {
                        oaSchema = openapiSchema?.components?.schemas?.[sn] as object;
                        return oaSchema !== undefined;
                    });
                } else {
                    oaSchema = openapiSchema?.components?.schemas?.[schemaName] as object;
                }

                if (oaSchema) {
                    const schema = resolveSchemaRef(openapiSchema, oaSchema) as JSONSchema7;
                    if (schema.type === "object" && schema.properties) {
                        let requiredProps: string[] = [];
                        if (schema.required && schema.required.length) {
                            requiredProps = schema.required;
                        } else if (typeof schema.required === "object") {
                            requiredProps = Object.values(schema.required);
                        }
                        Object.entries(schema.properties).forEach(([p, propSchema]) => {
                            if (typeof propSchema === "object") {
                                const fieldParams: FormFieldParams<FormValues>[keyof FormFieldParams<FormValues>] = {
                                    type: "text",
                                    required: requiredProps.includes(p),
                                    validators: [],
                                    attributes: {},
                                };

                                switch (propSchema.format) {
                                    case "email":
                                    case "password":
                                        fieldParams.type = propSchema.format;
                                        break;
                                    case "uuid":
                                        fieldParams.validators.push(val => typeof val !== "undefined" ? ((val + "").match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/) ? undefined : "Invalid UUID!") : undefined);
                                        break;
                                }
                                if (fieldParams.required) {
                                    fieldParams.attributes.required = true;
                                    fieldParams.validators.push(val => val ? undefined : "Required");
                                }
                                if (typeof propSchema.minLength === "number") {
                                    fieldParams.attributes.minLength = propSchema.minLength;
                                    fieldParams.validators.push(val => {
                                        const len: number = typeof val === "string" ? val.length : 0;
                                        return len >= (propSchema.minLength || 0) ? undefined : `Value length should be grater than ${propSchema.minLength}`;
                                    });
                                }
                                if (typeof propSchema.maxLength === "number") {
                                    fieldParams.attributes.maxLength = propSchema.maxLength;
                                    fieldParams.validators.push(val => {
                                        const len: number = typeof val === "string" ? val.length : 0;
                                        return len <= (propSchema.maxLength || 0) ? undefined : `Value length should be less than ${propSchema.maxLength}`;
                                    });
                                }

                                props[p as keyof FormFieldParams<FormValues>] = fieldParams;

                                if (propSchema.format === "password" && fieldParams) {
                                    const confirmFieldParams = {...fieldParams};
                                    confirmFieldParams.validators = [...fieldParams.validators];
                                    confirmFieldParams.validators.push((val, values) => {
                                        return val === values[p] ? undefined : `Values of "${p}" and "${p}-confirm" not equals`;
                                    });

                                    props[(`${p}-confirm`) as keyof FormFieldParams<FormValues>] = confirmFieldParams;
                                }
                            }
                        });
                    }
                }
            }

            return props;
        },
        [openapiSchema]
    );
}

export function getUserFullName(user: AccountInitialState): string {
    let fullName = "";
    if (user.firstName || user.lastName) {
        fullName += `${user.firstName} ${user.lastName}`.trim();
    }
    if (fullName === "") {
        fullName = user.email || user.username || "";
    }

    if (fullName === "") {
        fullName = `User ID: ${user.id}`;
    }

    return fullName;
}

