import classNames from "classnames";
import Input from "~/components/common/Input";
import Button from "~/components/common/Button";
import Field from "~/components/common/Field";
import ErrorMessage from "~/components/common/ErrorMessage";

import Form from "~/components/common/Form";

import {createStylesSelector, getUserFullName, prepareFormFieldParamsFromSchema} from "~/lib";

import Loader from "~/components/common/Loader";
import type {ErrorMessageProps} from "~/@types/components/common/ErrorMessageProps";
import {useGetSchemaQuery} from "~/api/baseAPI";
import {useContext, useId} from "react";
import recaptchaContext from "~/context/RecaptchaContext";
import type {LoginFormProps, LoginValues} from "~/@types/components/forms/LoginFormProps";
import Checkbox from "~/components/common/Checkbox";
import Span from "~/components/common/Span";

export default function LoginForm({
    name,
    loading,
    classes: propsClasses,
    styles: propsStyles,
    initialValues,
    onSubmit,
    currentUser,
    ...props
}: LoginFormProps) {
    const styles = createStylesSelector([propsClasses, propsStyles]);
    const {data: openapiSchema, isLoading: openapiLoading, error: openapiError} = useGetSchemaQuery();

    const id = useId();

    const formFieldParams = prepareFormFieldParamsFromSchema<LoginValues>(
        !openapiLoading && !openapiError ? openapiSchema : undefined,
        ["SchemasAuthRequest"]
    );

    return <Form<LoginValues>
        {...props}
        initialValues={{...initialValues, "current-user": currentUser?.email}}
        name={name || "login-form"}
        classes={{}}
        styles={styles}
        className={classNames(props.className, styles("login-form"))}
        onSubmit={onSubmit}
    >
        {({submitting, hasValidationErrors, error, submitError, touched}) => {
            const {error: recaptchaError, loading: recaptchaLoading,  ready: recaptchaReady} = useContext(recaptchaContext);

            const errors: ErrorMessageProps["error"][] = [];
            if (touched && error) {
                errors.push(error);
            }
            if (submitError) {
                errors.push(submitError);
            }
            if (recaptchaError) {
                errors.push(recaptchaError);
            }
            if (openapiError) {
                errors.push(openapiError);
            }

            const hasInitialEmail = initialValues.email !== undefined && initialValues.email !== "";

            return <>
                {loading || submitting || recaptchaLoading ?
                    <Loader size="large" className={styles("loader").toString()}/> : null}
                {currentUser ? <div className={classNames(styles("row", "row-current"))}>
                    Currently logged in as <Span className={styles("username").toString()}
                        title={getUserFullName(currentUser)}>{currentUser.username}</Span>
                </div> : null}
                <div
                    className={classNames(styles("row", "row-email", formFieldParams["email"]?.required ? "required" : false))}>
                    <Field<LoginValues["current-user"]> name="current-user" type="hidden"
                        fieldParams={formFieldParams["current-user"]} styles={styles}/>
                    <Field<LoginValues["ret-url"]> name="ret-url" type="hidden" fieldParams={formFieldParams["ret-url"]}
                        styles={styles}/>
                    <label className={styles("label").toString()} htmlFor={`${id}-email`}>Email:</label>
                    <Field<LoginValues["email"]> name="email" type="email" fieldParams={formFieldParams["email"]}
                        styles={styles}>
                        {({input, fieldName, meta: {touched, error}}) => <>
                            <Input {...input} autoFocus={!hasInitialEmail} id={`${id}-email`} styles={styles}
                                autoComplete="username"
                                name={fieldName}/>
                            {touched && error ? <ErrorMessage styles={styles} error={error}/> : null}
                        </>}
                    </Field>
                </div>
                <div
                    className={classNames(styles("row", "row-password", formFieldParams["email"]?.required ? "required" : false))}>
                    <label className={styles("label").toString()} htmlFor={`${id}-password`}>Password:</label>
                    <Field<LoginValues["password"]> name="password" type="password"
                        fieldParams={formFieldParams["password"]} styles={styles}>
                        {({input, fieldName, meta: {touched, error}}) => <>
                            <Input {...input} autoFocus={hasInitialEmail} id={`${id}-password`} styles={styles}
                                autoComplete="password"
                                name={fieldName}/>
                            {touched && error ? <ErrorMessage styles={styles} error={error}/> : null}
                        </>}
                    </Field>
                </div>
                <div
                    className={classNames(styles("row", "row-long-term-token", formFieldParams["long-term-token"]?.required ? "required" : false))}>
                    <Field<LoginValues["long-term-token"]> name="long-term-token" type="checkbox"
                        fieldParams={formFieldParams["long-term-token"]}
                        styles={styles}>
                        {({input, fieldName, meta: {touched, error}}) => <>
                            <Checkbox {...input} id={`${id}-long-term-token`} styles={styles} name={fieldName}>Remember
                                me</Checkbox>
                            {touched && error ? <ErrorMessage styles={styles} error={error}/> : null}
                        </>}
                    </Field>
                </div>
                <div
                    className={classNames(styles("row", "row-logout-other", formFieldParams["long-logout-other"]?.required ? "required" : false))}>
                    <Field<LoginValues["logout-other"]> name="logout-other" type="checkbox"
                        fieldParams={formFieldParams["logout-other"]}
                        styles={styles}>
                        {({input, fieldName, meta: {touched, error}}) => <>
                            <Checkbox {...input} id={`${id}-logout-other`} styles={styles} name={fieldName}>Log-out other sessions</Checkbox>
                            {touched && error ? <ErrorMessage styles={styles} error={error}/> : null}
                        </>}
                    </Field>
                </div>
                <div className={classNames(styles("row", "row-error"))}>
                    {errors.map((e, i) => <ErrorMessage
                        key={i}
                        styles={styles}
                        className={"" + styles("login-error")}
                        error={e}
                        skipErrCodes
                    />)}
                </div>
                <div className={classNames(styles("row", "row-submit"))}>
                    <Button
                        ant="default"
                        className={"" + styles("submit")}
                        bold
                        disabled={recaptchaReady === false || hasValidationErrors || openapiLoading || recaptchaLoading || loading || submitting}
                        type="submit"
                        styles={styles}
                    >
                        {loading || submitting ? "loading..." : "Sign In!"}
                    </Button>
                </div>
            </>;
        }}
    </Form>;
}