import {configureStore} from "@reduxjs/toolkit";
import {baseAPI} from "~/api/baseAPI";
import {rootReducer, type RootState} from "~/reducers";
import {useDispatch, useSelector} from "react-redux";

import {FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE} from "redux-persist";
import {PersistConfig} from "~/@types/reducers";
import storage from "redux-persist/lib/storage";
import {NonUndefined} from "~/@types";
import {useOnMountUnsafe} from "~/lib";
import {useState} from "react";

const apiMiddlewares = [
    baseAPI.middleware,
];

export function createStore(persist: boolean, persistConfig: PersistConfig) {
    return persist
        ? configureStore({
            reducer: persistReducer(
                {
                    ...persistConfig,
                    key: "root",
                    storage,
                },
                rootReducer
            ),
            middleware: (getDefaultMiddleware) => getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [
                        FLUSH,
                        REHYDRATE,
                        PAUSE,
                        PERSIST,
                        PURGE,
                        REGISTER,
                    ],
                }
            }).concat(apiMiddlewares),
        })
        : configureStore({
            reducer: rootReducer,
            middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiMiddlewares),
        });
}

export function getStore(persist: boolean, persistConfig: PersistConfig) {
    const storeKey = `persist: "${persist}" whitelist: ${persist && persistConfig?.whitelist || []} blacklist: ${persist && persistConfig?.blacklist || []}`;
    const [store, setStore] = useState<ReturnType<typeof createStore>>();
    useOnMountUnsafe(
        () => {
            setStore(createStore(persist, persistConfig));
        },
        [storeKey],
        storeKey
    );

    return store;
}

export type StoreType = NonUndefined<ReturnType<typeof createStore>>

export const useAppDispatch = () => useDispatch<StoreType["dispatch"]>();
export const useAppState = () => useSelector((state: RootState)=>state);