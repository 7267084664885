import classNames from "classnames";

import classes from "./PageDocsReference.module.pcss";
import {classPrefix, createStylesSelector, sanitizePath} from "~/lib";
import type {PageDefaultProps} from "~/@types/components/pages/PageDefaultProps";

import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import {useSelector} from "react-redux";
import {getAuthToken, getJWTToken} from "~/reducers/account";

export default function PageDocsReference({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName
}: PageDefaultProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    const authToken = useSelector(getAuthToken);
    const jwtToken = useSelector(getJWTToken);
    return <section className={classNames(classPrefix("page-docs-reference"), propsClassName, styles("page-docs-reference"))}>
        <h1>API Reference (OpenAPI Spec)</h1>
        <SwaggerUI
            url={sanitizePath(`${VITE__API_ENDPOINT}/openapi.json`)}
            onComplete={api => {
                if (authToken) {
                    api.preauthorizeApiKey("api_key", authToken);
                }
                if (jwtToken) {
                    api.preauthorizeApiKey("jwt", jwtToken);
                }
            }}

        />
    </section>;
}
