import classNames from "classnames";
import {useSelector} from "react-redux";

import {getPageTemplates, getPageTitle} from "~/reducers/page";

import * as Pages from "~/components/pages";

import classes from "./Page.module.pcss";
import {applyTpl, classPrefix, createStylesSelector, isNumber} from "~/lib";
import type {PageProps, PageTplName} from "~/@types/components/templates/PageProps";
import {Helmet} from "react-helmet-async";
import {useParams, useSearchParams} from "react-router";

export default function Page({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    ...props
}: PageProps) {
    const pageTemplates = useSelector(getPageTemplates);
    const pageTitle = useSelector(getPageTitle);
    const params = useParams();
    const [searchParams] = useSearchParams();

    let docTitle = document.head.title;

    const titleTpl = document.head.getElementsByTagName("title")[0].dataset.template;
    if (titleTpl !== undefined && pageTitle) {
        const pageParams: {[key: string]: string} = {};
        searchParams.forEach((v, k) => {
            pageParams[k] = v;
        });
        Object.entries(params).forEach(([k, v]) => {
            if (typeof v !== "undefined") {
                pageParams[k] = v;
            }
        });
        docTitle = applyTpl(titleTpl, {title: applyTpl(pageTitle, pageParams)});
    }

    type PageComponent = typeof Pages[PageTplName];
    let PageTplComponent: null | PageComponent;
    if (pageTemplates.length) {
        PageTplComponent = pageTemplates.reduce<PageComponent | null>(
            (CurrentPageTplComponent: PageComponent | null, tpl) => {
                if (isNumber(tpl)) {
                    tpl = "_" + tpl;
                }
                if (!CurrentPageTplComponent && Pages[tpl]) {
                    CurrentPageTplComponent = Pages[tpl];
                }
                return CurrentPageTplComponent;
            },
            null
        ) || Pages.Default;
    } else {
        PageTplComponent = null;
    }

    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    return <>
        <Helmet>
            <title>{docTitle}</title>
        </Helmet>
        {PageTplComponent ? <PageTplComponent
            {...props}
            className={classNames(classPrefix("page"), propsClassName, styles("page"))}
            styles={styles}
        /> : null}
    </>;

}