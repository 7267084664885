import classNames from "classnames";
import {Field as ReactFinalFormField, FieldInputProps as ReactFinalFormFieldInputProps} from "react-final-form";

import {FieldContext, getFormFieldContextProps} from "~/context/FormFieldContext";

import classes from "./Field.module.pcss";
import {capitalize, classPrefix, createEventHandler, createStylesSelector, wrapTargetEvent} from "~/lib";
import type {FieldProps} from "~/@types/components/common/FieldProps";
import type {EventHandler} from "~/@types";
import {useCallback} from "react";
import {FieldValidator} from "final-form";

export default function Field<FieldValue = any>({
    children = ({input, fieldName}) => <input {...input} name={fieldName}/>,
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    fieldParams,
    ...props
}: FieldProps<FieldValue>) {
    const {
        name,
        ...contextProps
    } = getFormFieldContextProps(props);

    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    const createEventProps = (input: ReactFinalFormFieldInputProps<FieldValue, HTMLElement>) => [
        "change",
        "blur",
        "focus",
    ].reduce<Partial<ReactFinalFormFieldInputProps<any>>>(
        (props, eventType) => {
            const eventProp = `on${capitalize(eventType)}`;
            props[eventProp] = useCallback((evt: any) => {
                const handler = createEventHandler(
                    [
                        input[eventProp] as EventHandler,
                        contextProps[eventProp],
                    ],
                    e => wrapTargetEvent(e, "change", {name})
                );
                if (typeof handler === "function") {
                    handler(evt);
                }
            }, [input.name]);
            return props;
        },
        {}
    );

    const validate: FieldValidator<FieldValue> | undefined = fieldParams && fieldParams.validators && fieldParams.validators.length
        ? (value, allValues, meta) => fieldParams.validators.reduce<string[] | undefined>(
            (error, validator) => {
                const currentError = validator(value, allValues, meta);
                if (currentError) {
                    if (!error) {
                        error = [];
                    }
                    if (typeof currentError === "object" ) {
                        error.push(...Object.values(currentError).map(v => "" + v));
                    } else {
                        error.push("" + currentError);
                    }
                }
                return error;
            },
            undefined
        )
        : undefined;

    return <ReactFinalFormField<FieldValue>
        {...props}
        name={props.name}
        onChange={contextProps.onChange}
        className={classNames(classPrefix("field"), propsClassName, styles("field"))}
        validate={validate}
    >
        {({input, ...props}) => <FieldContext.Provider value={{
            name,
            onChange: contextProps.onChange
        }}>
            {children({
                input: {
                    required: fieldParams && fieldParams.required,
                    type: fieldParams && fieldParams.type,
                    ...(fieldParams && fieldParams.attributes || {}),
                    ...input,
                    name,
                    ...createEventProps(input),
                },
                ...props,
                fieldName: name,
                name
            })}
        </FieldContext.Provider>}
    </ReactFinalFormField>;
}