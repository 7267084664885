import type {RecaptchaProps} from "~/@types/components/common/RecaptchaProps";
import {useGetAuthSignUpDataQuery} from "~/api/enhancedAuthAPI";
import {Helmet} from "react-helmet-async";
import {useEffect, useState} from "react";
import RecaptchaContext from "~/context/RecaptchaContext";

export default function Recaptcha({children}: RecaptchaProps) {
    const [ready, setReady] = useState(false);
    const [error, setError] = useState<unknown>(undefined);
    const {data: signupData, isLoading: signupDataLoading, error: signupDataError} = useGetAuthSignUpDataQuery();

    const getToken = async () => {
        return new Promise<string>((resolve, reject) => {
            const siteKey = signupData && signupData["recaptcha-site-key"];
            if (!grecaptcha) {
                return reject("grecaptcha not defined");
            }
            if (!siteKey) {
                return reject("empty site key");
            }
            grecaptcha.ready(function() {
                try {
                    return grecaptcha.execute(siteKey, {action: "submit"})
                        .then(token => resolve(token));
                } catch (e) {
                    return reject(e);
                }
            });
        });
    };

    const checkRecaptchaReady = async () => new Promise<boolean>((resolve, reject) => {
        const checkReady = (resolve, reject, i) => {
            if (typeof grecaptcha !== "undefined") {
                grecaptcha.ready(() => resolve(true));
            } else {
                if (i > 60) {
                    reject("timeout");
                }
                setTimeout(() => checkReady(resolve, reject, i + 1), 100);
            }
        };
        checkReady(resolve, reject, 0);
    });

    useEffect(() => {
        if (signupData) {
            (async () => {
                try {
                    setReady(await checkRecaptchaReady());
                } catch (e) {
                    setError(e);
                }
            })();
        }
    }, [signupData]);

    return <RecaptchaContext.Provider value={{
        ready,
        loading: signupDataLoading,
        getToken,
        error: signupDataError || error,
    }}>
        <Helmet>
            {signupData && signupData["recaptcha-site-key"] ? <script async src={`https://www.google.com/recaptcha/api.js?render=${signupData["recaptcha-site-key"]}`}/> : null}
        </Helmet>
        {children}
    </RecaptchaContext.Provider>;
}