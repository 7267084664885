import {ConfigProvider} from "antd";
import {ReactNode, StrictMode, useRef, useState} from "react";
import {HelmetProvider} from "react-helmet-async";
import {Provider, useSelector} from "react-redux";
import {persistStore} from "redux-persist";
import {PersistGate} from "redux-persist/integration/react";

import FingerprintJS from "@fingerprintjs/fingerprintjs";
import {enhancedUserAPI} from "~/api/enhancedUserAPI";
import {enhancedNavigationAPI} from "~/api/enhancedNavigationAPI";
import {classPrefix, useOnMountUnsafe} from "~/lib";

import {AppContext} from "~/context/AppContext";

import {getAppStatus, init as appInit} from "~/reducers/app";
import {getAccountState, init as accountInit} from "~/reducers/account";
import {getNavigationStatus, init as navigationInit} from "~/reducers/navigation";

import {getStore, useAppDispatch} from "~/store";

import config from "~/config";

import "reset-css";
import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";
import "@fontsource-variable/roboto-mono/wght.css";
import "@fontsource/ubuntu/300.css";
import "@fontsource/ubuntu/400.css";
import "@fontsource/ubuntu/500.css";
import "@fontsource/ubuntu/700.css";
import "@fontsource/ubuntu-mono/400.css";
import "@fontsource/ubuntu-mono/700.css";
import "~/main.css";
import "~/variables.css";
import Router from "~/components/features/Router";
import {AppProps} from "~/@types/components/features/AppProps";
import {ContainerRefElementType} from "~/@types/context/AppContextProps";

function AppWrapper({RouteComponent}: AppProps) {
    const dispatch = useAppDispatch();
    const [initiating, setInitiating] = useState({});
    const statuses = {
        app: useSelector(getAppStatus),
        account: useSelector(getAccountState),
        navigation: useSelector(getNavigationStatus),
    };
    useOnMountUnsafe(
        () => {
            // noinspection JSUnusedGlobalSymbols
            const initChain = {
                app: async () => {
                    const fp = await FingerprintJS.load();
                    const fingerprint = await fp.get();
                    dispatch(appInit({fingerprint}));
                },
                account: async () => {
                    dispatch(accountInit());
                    const userAPIPromise = dispatch(enhancedUserAPI.endpoints.getUserByUserId.initiate(
                        "current", {
                            subscriptionOptions: {
                                pollingInterval: 15000,
                                skipPollingIfUnfocused: true,
                            },
                        }
                    ));
                    // noinspection JSUnresolvedReference
                    // userAPIPromise.unsubscribe();
                    await userAPIPromise;
                },
                navigation: async () => {
                    dispatch(navigationInit());
                    const navigationAPIPromise = dispatch(enhancedNavigationAPI.endpoints.getNavigationMenus.initiate());
                    // noinspection JSUnresolvedReference
                    // navigationAPIPromise.unsubscribe();
                    await navigationAPIPromise;
                },
            };
            Object.keys(initChain).forEach(key => {
                const callback = initChain[key];
                if (statuses[key] !== "loading" && !initiating[key]) {
                    setInitiating({
                        ...initiating,
                        [key]: true
                    });
                    callback();
                }
            });
        },
        []
    );

    return <StrictMode>
        <Router RouteComponent={RouteComponent} pages={config.routes} />
    </StrictMode>;
}

function StoreProvider({persist, persistConfig, persistLoading, children}: AppProps & {children: ReactNode}) {
    const store = getStore(persist, persistConfig || {});

    const persistor = persist && store ? persistStore(store) : undefined;

    return store ? <Provider store={store}>
        {persist && persistor
            ? <PersistGate persistor={persistor} loading={persistLoading}>{children}</PersistGate>
            : children}
    </Provider> : "initializing store...";
}

export default function App(props: AppProps) {
    const popupContainer = useRef<ContainerRefElementType>(null);

    return <HelmetProvider>
        <StoreProvider {...props}>
            <ConfigProvider theme={{cssVar: {prefix: "ant"}}} prefixCls={classPrefix("ant")}>
                <AppContext.Provider value={{popupContainer}}>
                    <AppWrapper {...props}/>
                </AppContext.Provider>
            </ConfigProvider>
        </StoreProvider>
    </HelmetProvider>;
}
