import classNames from "classnames";
import Menu from "~/components/common/Menu";
import SVG from "~/components/common/SVG";

import classes from "./Header.module.pcss";
import {classPrefix, createStylesSelector} from "~/lib";
import type {HeaderProps} from "~/@types/components/templates/HeaderProps";
import {useDispatch, useSelector} from "react-redux";
import {getAppTheme, setAppTheme} from "~/reducers/app";
import Button from "~/components/common/Button";
import {APP_ALLOWED_THEMES} from "~/constants/App";
import Span from "~/components/common/Span";
import type {AllowedThemes} from "~/@types";
import {ReactNode} from "react";
import {getAccountGreetingName, isAnonymous as isAnonymousSelector} from "~/reducers/account";
import type {MenuItem} from "~/@types/components/common/MenuProps";
import {Slug} from "~/config";
import {LinkProps} from "~/@types/components/common/LinkProps";
import {getSlugInfo} from "~/reducers/navigation";

export default function Header({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
}: HeaderProps) {
    const {path: signupSlugPath} = useSelector(getSlugInfo)(Slug.Signup);
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    const dispatch = useDispatch();

    const isAnonymous = useSelector(isAnonymousSelector);
    const accountGreetingName = useSelector(getAccountGreetingName);

    const currentTheme = useSelector(getAppTheme);
    let themeIndex = APP_ALLOWED_THEMES.indexOf(currentTheme);
    if (themeIndex < 0) {
        themeIndex = 0;
    }

    const nextTheme = themeIndex + 1 < APP_ALLOWED_THEMES.length
        ? APP_ALLOWED_THEMES[themeIndex + 1]
        : APP_ALLOWED_THEMES[0];

    const themeTitles = {
        "default": "System",
        "dark": "Dark",
        "light": "Light",
    };

    const getThemeSwitcherButton = (theme: AllowedThemes, nextTheme: AllowedThemes, title: string, children: ReactNode = null, addActive: boolean = false) => {
        return <Button title={title} styles={styles} className={classNames(styles(
            "theme-switcher",
            `theme-${theme}`,
            children ? "with-children" : false,
            addActive && theme === currentTheme ? "active" : false
        ))} flat onClick={() => dispatch(setAppTheme(nextTheme))}>
            <Span styles={styles} className={styles("theme-icons") + ""}>
                <Span styles={styles} className={styles("theme-icon", "light") + ""} ico={{ico: "Sun", mode: "outlined", loader: false}}/>
                <Span styles={styles} className={styles("theme-icon", "dark") + ""} ico={{ico: "Moon", mode: "outlined", loader: false}}/>
            </Span>
            {children}
        </Button>;
    };

    const accountItems: MenuItem[] = [];

    const withRetURL: LinkProps["withRetURL"] = (toURLScheme, location) =>
        toURLScheme.pathname !== location.pathname && location.pathname !== signupSlugPath;

    if (isAnonymous) {
        accountItems.push(
            {
                slug: Slug.Login,
                props: {ico: {ico: "Login", mode: "outlined", loader: false}, withRetURL},
            },
            {
                slug: Slug.Signup,
                props: {ico: {ico: "Edit", mode: "outlined", loader: false}},
            }
        );
    } else {
        accountItems.push(
            {
                slug: Slug.Logout,
                props: {ico: {ico: "Logout", mode: "outlined", loader: false}, withRetURL: true},
            }
        );
    }

    const itemsAppend = [
        {
            slug: isAnonymous ? Slug.Login : Slug.Account,
            props: {
                ico: {ico: "User", mode: "outlined", loader: false},
                icoPosition: isAnonymous ? "only" : "before",
                withRetURL,
            },
            title: isAnonymous ? undefined : `Hello, ${accountGreetingName}`,
            items: accountItems,
        },
        {
            key: "theme-switcher",
            children: getThemeSwitcherButton(
                currentTheme,
                nextTheme,
                `Current theme: "${themeTitles[currentTheme]}". Switch to: "${themeTitles[nextTheme]}" theme`
            ),
            items: APP_ALLOWED_THEMES.map(theme => ({
                key: `theme-switcher-${theme}`,
                children: getThemeSwitcherButton(
                    theme,
                    theme,
                    `Switch to: "${themeTitles[theme]}" theme`,
                    themeTitles[theme],
                    true
                )
            })),
        }
    ];

    return <header className={classNames(classPrefix("header"), propsClassName, styles("header"))}>
        <SVG styles={styles} className={"" + styles("logo")} path="assets/logo.svg" link linkProps={{slug: "index"}}/>
        <nav className={"" + styles("menu")}>
            <Menu
                styles={styles}
                name="top"
                mode="horizontal"
                subMenuClassName={"" + styles("header-sub-menu")}
                itemsAppend={itemsAppend}
            />
        </nav>
    </header>;
}