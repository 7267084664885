import {navigationAPI} from "~/api/navigationAPI";
import {TagAuth} from "~/constants/Tags";

export const enhancedNavigationAPI = navigationAPI.enhanceEndpoints({
    addTagTypes: [TagAuth],
    endpoints: {getNavigationMenus: {providesTags: () => [{type: TagAuth, id: "current"}]}}
});

export const {
    useGetNavigationMenusQuery,
    useLazyGetNavigationMenusQuery,
} = enhancedNavigationAPI;