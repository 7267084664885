import Header from "~/components/templates/Header";
import Main from "~/components/templates/Main";
import Footer from "~/components/templates/Footer";

import classes from "./Wrapper.module.pcss";
import {classPrefix, createStylesSelector} from "~/lib";
import type {WrapperProps} from "~/@types/components/templates/WrapperProps";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {getAppTheme} from "~/reducers/app";
import ErrorBoundary from "~/components/common/ErrorBoundary";
import {AppContext} from "~/context/AppContext";
import {useContext} from "react";

export default function Wrapper({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    ...props
}: WrapperProps) {
    const {popupContainer} = useContext(AppContext);
    const theme = useSelector(getAppTheme);
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    return <div className={styles("app-wrapper", `theme-${theme}`) + ""}>
        <div className={classNames(classPrefix("wrapper"), propsClassName, styles("wrapper", `theme-${theme}`))}>
            <ErrorBoundary>
                <Header  {...props} className={"" + styles("header")} styles={styles} classes={classes}/>
                <Main {...props} className={"" + styles("main")} styles={styles} classes={classes}/>
                <Footer {...props} className={"" + styles("footer")} styles={styles} classes={classes}/>
            </ErrorBoundary>
        </div>
        <div className={styles("popup-container") + ""} ref={popupContainer}></div>
    </div>;
}