import classNames from "classnames";
import Input from "~/components/common/Input";
import Button from "~/components/common/Button";
import Field from "~/components/common/Field";
import ErrorMessage from "~/components/common/ErrorMessage";

import Form from "~/components/common/Form";

import {createStylesSelector, prepareFormFieldParamsFromSchema} from "~/lib";

import type {SignupFormProps, SignupValues} from "~/@types/components/forms/SignupFormProps";
import Loader from "~/components/common/Loader";
import type {ErrorMessageProps} from "~/@types/components/common/ErrorMessageProps";
import {useGetSchemaQuery} from "~/api/baseAPI";
import {useContext, useId} from "react";
import recaptchaContext from "~/context/RecaptchaContext";

export default function SignupForm({
    name,
    loading,
    classes: propsClasses,
    styles: propsStyles,
    initialValues,
    onSubmit,
    predefined = false,
    ...props
}: SignupFormProps) {
    const styles = createStylesSelector([propsClasses, propsStyles]);
    const {data: openapiSchema, isLoading: openapiLoading, error: openapiError} = useGetSchemaQuery();

    const id = useId();

    const formFieldParams = prepareFormFieldParamsFromSchema<SignupValues>(
        !openapiLoading && !openapiError ? openapiSchema : undefined,
        ["AuthInputSignupObjectCaptcha", "AuthInputUserObjectCaptcha"]
    );

    return <Form<SignupValues>
        {...props}
        initialValues={initialValues}
        name={name || "login-form"}
        classes={{}}
        styles={styles}
        className={classNames(props.className, styles("login-form"))}
        onSubmit={onSubmit}
        withRecaptcha
    >
        {({submitting, hasValidationErrors, error, submitError, touched}) => {
            const {error: recaptchaError, loading: recaptchaLoading,  ready: recaptchaReady} = useContext(recaptchaContext);

            const errors: ErrorMessageProps["error"][] = [];
            if (touched && error) {
                errors.push(error);
            }
            if (submitError) {
                errors.push(submitError);
            }
            if (recaptchaError) {
                errors.push(recaptchaError);
            }
            if (openapiError) {
                errors.push(openapiError);
            }

            return <>
                {loading || submitting || recaptchaLoading ?
                    <Loader size="large" className={styles("loader").toString()}/> : null}
                <div
                    className={classNames(styles("row", "row-email", formFieldParams["email"]?.required ? "required" : false))}>
                    <Field<SignupValues["invite-id"]> name="invite-id" type="hidden"
                        fieldParams={formFieldParams["invite-id"]} styles={styles}/>
                    <label htmlFor={`${id}-email`}>Email:</label>
                    <Field<SignupValues["email"]> name="email" type="email" fieldParams={formFieldParams["email"]}
                        styles={styles}>
                        {({input, fieldName, meta: {touched, error}}) => <>
                            <Input {...input} id={`${id}-email`} autoFocus styles={styles} autoComplete="off" name={fieldName}
                                readOnly={predefined}/>
                            {touched && error ? <ErrorMessage styles={styles} error={error}/> : null}
                        </>}
                    </Field>
                </div>
                <div
                    className={classNames(styles("row", "row-password", formFieldParams["password"]?.required ? "required" : false))}>
                    <label htmlFor={`${id}-password`}>Password:</label>
                    <Field<SignupValues["password"]> name="password" type="password"
                        fieldParams={formFieldParams["password"]} styles={styles}>
                        {({input, fieldName, meta: {touched, error}}) => <>
                            <Input {...input} id={`${id}-password`} styles={styles} autoComplete="new-password"
                                name={fieldName}/>
                            {touched && error ? <ErrorMessage styles={styles} error={error}/> : null}
                        </>}
                    </Field>
                </div>
                <div
                    className={classNames(styles("row", "row-password-confirm", formFieldParams["password-confirm"]?.required ? "required" : false))}>
                    <label htmlFor={`${id}-password-confirm`}>Confirm Password:</label>
                    <Field<SignupValues["password-confirm"]> name="password-confirm" type="password" fieldParams={formFieldParams["password-confirm"]} styles={styles}>
                        {({input, fieldName, meta: {touched, error}}) => <>
                            <Input {...input} id={`${id}-password-confirm`} styles={styles} autoComplete="new-password"
                                name={fieldName}/>
                            {touched && error ? <ErrorMessage styles={styles} error={error}/> : null}
                        </>}
                    </Field>
                </div>
                <div className={classNames(styles("row", "row-error"))}>
                    {errors.map((e, i) => <ErrorMessage
                        key={i}
                        styles={styles}
                        className={"" + styles("sign-up-error")}
                        error={e}
                        skipErrCodes
                    />)}
                </div>
                <div className={classNames(styles("row", "row-submit"))}>
                    <Button
                        ant="default"
                        className={"" + styles("submit")}
                        bold
                        disabled={!recaptchaReady || hasValidationErrors || openapiLoading || recaptchaLoading || loading || submitting}
                        type="submit"
                        styles={styles}
                    >
                        {loading || submitting ? "loading..." : "Sign Up!"}
                    </Button>
                </div>
            </>;
        }}
    </Form>;
}