import {combineSlices} from "@reduxjs/toolkit";

import appSlice from "./app";
import accountSlice from "./account";
import navigationSlice from "./navigation";
import pageSlice from "./page";

import {enhancedUserAPI} from "~/api/enhancedUserAPI";
import {enhancedAuthAPI} from "~/api/enhancedAuthAPI";
import {enhancedNavigationAPI} from "~/api/enhancedNavigationAPI";
import {docsAPI} from "~/api/docsAPI";
import {mediaAPI} from "~/api/mediaAPI";

export const rootReducer = combineSlices(
    enhancedUserAPI,
    enhancedAuthAPI,
    mediaAPI,
    enhancedNavigationAPI,
    docsAPI,
    appSlice,
    accountSlice,
    navigationSlice,
    pageSlice
);

export type RootState = ReturnType<typeof rootReducer>