import classNames from "classnames";
import {
    Form as ReactFinalForm,
    FormProps as ReactFinalFormFormProps,
    FormRenderProps as ReactFinalFormRenderProps
} from "react-final-form";

import {FormContext, getFormFieldContextProps} from "~/context/FormFieldContext";

import classes from "./Form.module.pcss";
import {classPrefix, createStylesSelector, deepEqual, useOnMountUnsafe} from "~/lib";
import type {FormProps, FormWrapperProps} from "~/@types/components/common/FormProps";
import {FORM_ERROR, SubmissionErrors as FinalFormSubmissionErrors} from "final-form";
import Recaptcha from "~/components/features/Recaptcha";
import {useContext, useRef, useState} from "react";
import recaptchaContext from "~/context/RecaptchaContext";

export default function Form<FormValues>({
    withRecaptcha = false,
    children,
    ...props
}: FormWrapperProps<FormValues>){
    return withRecaptcha
        ? <Recaptcha><FormInner<FormValues> {...props}>{children}</FormInner></Recaptcha>
        : <FormInner<FormValues> {...props}>{children}</FormInner>;
}

export function FormInner<FormValues>({
    children,
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    autoSubmitTime = -1,
    ...props
}: FormProps<FormValues>) {
    const {
        name,
        onChange,
        onSubmit
    } = getFormFieldContextProps(props);

    const {getToken: getRecaptchaToken} = useContext(recaptchaContext);

    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    const [autoSubmitTimeLeft, setAutoSubmitTimeLeft] = useState(autoSubmitTime);

    const intervalRef = useRef<ReturnType<typeof setInterval>>();
    const formRef = useRef<ReactFinalFormRenderProps<FormValues>["form"]>();
    const autoSubmitRef = useRef<boolean>();

    useOnMountUnsafe(
        () => {
            if (autoSubmitTime > 0 && !intervalRef.current) {
                intervalRef.current = setInterval(() => {
                    setAutoSubmitTimeLeft(prev => {
                        if (prev === 1) {
                            clearInterval(intervalRef.current);
                            if (!autoSubmitRef.current) {
                                autoSubmitRef.current = true;
                                setTimeout(async () => {
                                    if (formRef.current) {
                                        await formRef.current.submit();
                                        autoSubmitRef.current = false;
                                    }
                                });
                            }
                        }
                        return prev - 1;
                    });
                }, 1000);
            }
            return () => {
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }
            };
        },
        [autoSubmitTime],
        autoSubmitTime
    );

    if (intervalRef.current && autoSubmitTimeLeft <= 0) {
        clearInterval(intervalRef.current);
    }

    const renderForm = ({handleSubmit, form, ...props}: ReactFinalFormRenderProps<FormValues>) => {
        formRef.current = form;

        return  <FormContext.Provider value={{name, onChange}}>
            <form
                name={name}
                onSubmit={e => {
                    setAutoSubmitTimeLeft(-1);
                    return handleSubmit(e);
                }}
                className={classNames(
                    classPrefix("form"),
                    propsClassName,
                    styles(
                        "form",
                        props.pristine ? "pristine" : false,
                        props.invalid ? "invalid" : false,
                        props.hasSubmitErrors ? "has-submit-error" : false,
                        props.hasValidationErrors ? "has-validation-error" : false,
                        props.touched ? "touched" : false,
                        props.dirty ? "dirty" : false,
                        props.submitting ? "submitting" : false,
                        props.validating ? "validating" : false
                    )
                )}>
                {children({
                    form,
                    handleSubmit,
                    ...props,
                    autoSubmitTimeLeft,
                    cancelAutoSubmit: () => {
                        setAutoSubmitTimeLeft(-1);
                    }
                })}
            </form>
        </FormContext.Provider>;
    };

    const submitHandle: ReactFinalFormFormProps<FormValues>["onSubmit"] = async (values, form) => {
        try {
            let ret: FinalFormSubmissionErrors | Promise<FinalFormSubmissionErrors> | void;
            if (onSubmit) {
                if (getRecaptchaToken) {
                    values["recaptcha-token"] = await (getRecaptchaToken());
                }
                ret = await onSubmit(values, form);
            }

            return ret;
        } catch (e) {
            return {[FORM_ERROR]: e};
        }
    };

    return <ReactFinalForm<FormValues>
        initialValuesEqual={deepEqual}
        {...props}
        onSubmit={submitHandle}
    >
        {renderForm}
    </ReactFinalForm>;
}