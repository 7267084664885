import classNames from "classnames";

import classes from "./PageInvite.module.pcss";
import {classPrefix, createStylesSelector, queryStringify, sanitizePath} from "~/lib";
import type {PageDefaultProps} from "~/@types/components/pages/PageDefaultProps";

import {useNavigate, useParams, useSearchParams} from "react-router";
import {useLazyGetInviteByInviteIdCheckQuery} from "~/api/inviteAPI";
import Loader from "~/components/common/Loader";
import ErrorMessage from "~/components/common/ErrorMessage";
import {useEffect, useState} from "react";
import type {ErrorMessageProps} from "~/@types/components/common/ErrorMessageProps";
import {useSelector} from "react-redux";
import {getSlugInfo} from "~/reducers/navigation";
import type {GetInviteByInviteIdCheckApiArg} from "~/@types/api/inviteAPI";
import {Slug} from "~/config";

export default function PageInvite({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
}: PageDefaultProps) {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const inviteID = params["invite-id"];
    const email = searchParams.get("email");
    const [error, setInviteError] = useState<ErrorMessageProps["error"]>("");
    const {path: signupPath} = useSelector(getSlugInfo)(Slug.Signup);

    const [getInviteStatus] = useLazyGetInviteByInviteIdCheckQuery();

    const inviteParams: GetInviteByInviteIdCheckApiArg = {
        "invite-id": "",
        email: "",
    };

    if (inviteID) {
        inviteParams["invite-id"] = inviteID;
    }

    if (email) {
        inviteParams.email = email;
    }

    const navigate = useNavigate();

    useEffect(() => {
        if (email && inviteID) {
            (async () => {
                const {data: inviteData, isError, error: inviteError} = await getInviteStatus(inviteParams);
                if (isError) {
                    if (typeof inviteError === "object") {
                        setInviteError(inviteError as Error);
                    } else {
                        setInviteError("API error");
                    }
                } else if (inviteData) {
                    if (inviteData.active) {
                        navigate(sanitizePath(signupPath + "/?" + queryStringify(inviteParams)));
                    } else {
                        setInviteError("invite is inactive");
                    }
                }
            })();
        } else {
            setInviteError("invalid invite data");
        }
    }, [email, inviteID]);

    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    return <section className={classNames(classPrefix("page-invite"), propsClassName, styles("page-invite"))}>
        {error !== "" ? <ErrorMessage styles={styles} prefix="Invite check error: " error={error} /> : <Loader styles={styles} size="large"/>}
    </section>;
}
